import React, { useEffect } from 'react';
import PropTypes            from 'prop-types';
import { Form, Loader }     from 'semantic-ui-react';

import useApiFetch from 'hooks/useApiFetch';
import resourceUrl from 'utils/resourceUrl';

// -----------------------------------------------------
// Internal Functions
// -----------------------------------------------------

const buildQueryParams = (parametersMapping, answers) => (
  Object
  .entries({ ...parametersMapping })
  .map(([param, fieldId]) => ([
    param,
    answers[fieldId]?.value
  ]))
  .filter(([, value]) => value !== null && value !== undefined)
);

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const ApiResourceSelect = ({value,
                            disabled,
                            apiEndpoint,
                            parametersMapping,
                            answers,
                            onChange }) => {

  const initUrl                                     = resourceUrl(apiEndpoint);
  const [{ isLoading, isError, data }, url, setUrl] = useApiFetch(initUrl, []);
  const options                                     = isError ? [] : data;

  useEffect(() => {
    const params    = buildQueryParams(parametersMapping, answers);
    const nextUrl   = new URL(url);
    nextUrl.search  = new URLSearchParams(params);

    if(url?.href !== nextUrl?.href) {
      setUrl(nextUrl);
    }

  }, [answers, parametersMapping, setUrl, url]);

  return (
    <>
      <Form.Select  value={ value }
                    disabled={ disabled }
                    options={ options }
                    onChange={ onChange }
                    autoComplete='off'
                    clearable
                    search
                    selectOnBlur={ false } />

      { isLoading && <Loader active /> }
      { isError && <div>An error occurred: { data }</div> }
    </>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

ApiResourceSelect.defaultProps = {
  disabled:           false,
  value:              '',
  answers:            {},
  parametersMapping:  {}
};

ApiResourceSelect.propTypes = {
  parametersMapping:  PropTypes.object,
  answers:            PropTypes.object,
  disabled:           PropTypes.bool,
  value:              PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  apiEndpoint:  PropTypes.string.isRequired,
  onChange:     PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default ApiResourceSelect;
