import React, { useState }  from 'react';
import PropTypes            from 'prop-types';
import { Message }          from 'semantic-ui-react';
import { Link }             from 'react-router-dom';

import SortableTable                from 'components/SortableTable';
import useApiResource               from 'hooks/useApiResource';
import VersionSearchResultResource  from 'resources/admin/VersionSearchResultResource';

import formattedDate  from 'utils/formattedDate';

const headerLabels = {
  item_id:            'Record Id',
  item_type:          'Record Type',
  item_name:          'Record Title',
  user_name:          'Performed By',
  organization_name:  'Organization',
  created_at:         'Occurred At',
  transaction_id:     'Transaction Id',
  id:                 ' '
};

const orderedColumns = [
  'item_id',
  'item_type',
  'item_name',
  'user_name',
  'organization_name',
  'created_at',
  'transaction_id',
  'id'
];

const dateTimeColumnIndices = [5];
const idColumnIndex         = 7;

function cellRenderer(_record, value, columnIndex) {
  if(dateTimeColumnIndices.includes(columnIndex)) {
    return value && formattedDate(value);
  }

  if(columnIndex === idColumnIndex) {
    return (
      <Link to={ `/admin/versions/${value}` }>
        Rollback
      </Link>
    );
  }

  return value;
}

function getTableData(records) {
  const result = { headers: [], records: [] };

  if(!records || records.length === 0) {
    return result;
  }

  result.headers  = orderedColumns.map(key => headerLabels[key]);
  result.records  = records.map(rec => orderedColumns.map(key => rec[key]));

  return result;
}

// --------------------------------------------------------
// Component Definition
// --------------------------------------------------------

const ListView = ({ location }) => {
  const [page, setPage ]      = useState('');
  const { search }            = location;
  const searchParams          = new URLSearchParams(search);

  searchParams.set('page', page);

  const versions              = useApiResource(VersionSearchResultResource.listShape(), searchParams);
  const { headers, records }  = getTableData(versions);
  
  const handlePageNext = (page) => {
    setPage(page);
  };

  return (
    <div>
      {
        versions.length > 0
        ? <SortableTable  tableId='versions'
                          headerLabels={ headers }
                          records={ records }
                          cellRenderer={ cellRenderer }
                          onPageNext={ handlePageNext } />

        : <Message info>
            No records were found.
          </Message>
      }
    </div>
  );
};

// --------------------------------------------------------
// PropTypes
// --------------------------------------------------------

ListView.defaultProps  = {};

ListView.propTypes     = {
  location: PropTypes.object.isRequired
};

// --------------------------------------------------------
// Exports
// --------------------------------------------------------

export default ListView;
