import React, { Component }                    from 'react';
import PropTypes                               from 'prop-types';
import { Modal, Header, Form, Button, Table, Message, Icon }  from 'semantic-ui-react';

import uniqueId         from 'utils/uniqueId';
import PermissionFields from './PermissionFields';

// -----------------------------------------------------
// Helpers
// -----------------------------------------------------

const initialState = {
  recordPermissionChanges:    {},
  recordPermissionDeletions:  []
};

function composite(permissions, changes, deletions) {

  // Apply changes
  const withChanges   = { ...permissions, ...changes };

  // Apply deletions
  const withDeletions = deletions.reduce((permissions, id) => {
    delete permissions[id];
    return permissions;
  }, withChanges);

  return withDeletions;
}

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

class RecordPermissionsModal extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };

    this.formId = uniqueId();

    this.handleSubmit   = this.handleSubmit.bind(this);
    this.handleChange   = this.handleChange.bind(this);
    this.handleRemove   = this.handleRemove.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleCancel   = this.handleCancel.bind(this);
  }

  render() {
    const { opened, saving, onClose, loading, recordType, recordPermissions } = this.props;
    const { recordPermissionChanges, recordPermissionDeletions }  = this.state;

    const compositePermissions =
      composite(recordPermissions,
                recordPermissionChanges,
                recordPermissionDeletions);

    return (
      <Modal open={ opened } onClose={ onClose } dimmer='inverted' closeIcon>
        <Header icon='lock' content='Manage Permissions' />

        <Modal.Content>
          <h4>Add or Remove permissions for this record, below.</h4>

          <Form onSubmit={ this.handleSubmit }
                loading={ loading }
                id={ this.formId }>
            <Table basic='very'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>User / Permission Group</Table.HeaderCell>
                  <Table.HeaderCell>Read</Table.HeaderCell>
                  <Table.HeaderCell>Write</Table.HeaderCell>
                  <Table.HeaderCell>Remove</Table.HeaderCell>
                  <Table.HeaderCell textAlign='right'>
                    <Button icon='add'
                            size='mini'
                            basic
                            color='blue'
                            onClick={ this.handleAddClick } />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {
                  Object.entries(compositePermissions).map(([id, permission]) => (
                    <PermissionFields key={ id }
                                      id={ id }
                                      recordPermission={ permission }
                                      onChange={ this.handleChange }
                                      onRemove={ this.handleRemove } />
                  ))
                }
              </Table.Body>
            </Table>
          </Form>

          { recordType === 'sequences' && Object.keys(compositePermissions).length > 0 &&
            <Message warning>
              <Icon name='warning circle' />
                Please make sure, the User/Permission Group you selected has permissions to access all the forms in this sequence.
            </Message>
          }
        </Modal.Content>

        <Modal.Actions>
          <Button onClick={ this.handleCancel } negative basic>Cancel</Button>
          <Button type='submit'
                  loading={ saving }
                  form={ this.formId }
                  positive
                  basic>Save</Button>
        </Modal.Actions>
      </Modal>
    );
  }

  handleSubmit(evt) {
    evt.preventDefault();

    const { onSave, recordPermissions } = this.props;
    const { recordPermissionChanges, recordPermissionDeletions }   = this.state;

    const nextRecordPermissions =
      composite(recordPermissions,
                recordPermissionChanges,
                recordPermissionDeletions);

    onSave(nextRecordPermissions, () => {
      this.setState({ ...initialState });
    });
  }

  handleChange(evt, { value }) {
    const { recordPermissionChanges } = this.state;

    const nextRecordPermissionChanges = {
      ...recordPermissionChanges,
      ...value
    };

    this.setState({ recordPermissionChanges: nextRecordPermissionChanges });
  }

  handleRemove(evt, { id }) {
    const { recordPermissionDeletions } = this.state;

    this.setState({
      recordPermissionDeletions: [ ...recordPermissionDeletions, id ]
    });
  }

  handleAddClick(evt) {
    evt.preventDefault();
    const { recordPermissionChanges } = this.state;
    const key                         = uniqueId();
    const value                       = {};

    const nextRecordPermissionChanges = {
      ...recordPermissionChanges,
      [key]: value
    };

    this.setState({ recordPermissionChanges: nextRecordPermissionChanges });
  }

  handleCancel() {
    const { onClose } = this.props;

    onClose();
    this.setState(initialState);
  }
}

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

RecordPermissionsModal.defaultProps = {
  opened:   false,
  loading:  false
};

RecordPermissionsModal.propTypes = {
  opened:             PropTypes.bool,
  loading:            PropTypes.bool,
  recordPermissions:  PropTypes.object.isRequired,
  recordType:         PropTypes.string.isRequired,
  recordId:           PropTypes.oneOfType([
                        PropTypes.string,
                        PropTypes.number
                      ]).isRequired,
  onClose:            PropTypes.func.isRequired,
  onSave:             PropTypes.func.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default RecordPermissionsModal;
