import React, { useEffect, useState }         from 'react';
import PropTypes                              from 'prop-types';
import { Search as SearchComponent, Loader }  from 'semantic-ui-react';

import useApiFetch  from 'hooks/useApiFetch';
import resourceUrl  from 'utils/resourceUrl';

// -----------------------------------------------------
// Internals
// -----------------------------------------------------

const buildQueryParams = (parametersMapping, answers, id, searchTerm) => (
  Object
  .entries(parametersMapping)
  .map(([param, fieldId]) => ([
    param,
    fieldId === id ? searchTerm : answers[fieldId]?.value
  ]))
  .filter(([, value]) => value !== undefined && value !== null)
);

const getInitialTerm = (value) => {
  try {
    const data = JSON.parse(value);
    return data.title;
  } catch(e) {
    return '';
  }
};

// -----------------------------------------------------
// Component Definition
// -----------------------------------------------------

const Search = ({ fieldId,
                  name,
                  value,
                  onChange,
                  answers,
                  parametersMapping,
                  metadata: { api_endpoint } }) => {

  const initialTerm                                 = getInitialTerm(value);
  const [searchTerm, setSearchTerm]                 = useState(initialTerm);
  const initUrl                                     = resourceUrl(api_endpoint);
  const [{ isLoading, isError, data }, url, setUrl] = useApiFetch(initUrl, []);
  const { data: results }                           = data;

  useEffect(() => {
    const params    = buildQueryParams(parametersMapping, answers, fieldId, searchTerm);
    const nextUrl   = new URL(url);
    nextUrl.search  = new URLSearchParams(params);

    if(url?.href !== nextUrl?.href) {
      setUrl(nextUrl);
    }

  }, [fieldId, answers, parametersMapping, searchTerm, setUrl, url]);

  const handleSearchChange = (evt, { value }) => {
    setSearchTerm(value);
  };

  const handleResultSelect = (evt, { result }) => {
    const value = JSON.stringify(result);
    setSearchTerm(result.title);
    onChange(evt, { name, value });
  };

  return (
    <>
      <SearchComponent  value={ searchTerm }
                        results={ results }
                        onSearchChange={ handleSearchChange }
                        onResultSelect={ handleResultSelect } />

      { isLoading && <Loader active /> }
      { isError && <div>An error occurred: { data.errors }</div> }
    </>
  );
};

// -----------------------------------------------------
// PropTypes
// -----------------------------------------------------

Search.defaultProps = {
  value:              '',
  name:               '',
  answers:            {},
  parametersMapping:  {}
};

Search.propTypes = {
  value:                  PropTypes.string,
  name:                   PropTypes.string,
  answers:                PropTypes.object,
  parametersMapping:      PropTypes.object,
  fieldId:                PropTypes.number.isRequired
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default Search;
